import Vue from 'vue'
import App from './App.vue'
import router from './router'
import GoEasy from 'goeasy';

Vue.config.productionTip = false

Vue.prototype.globalData = {
    currentAgent: null
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

const goEasy = GoEasy.getInstance({
	host: 'hangzhou.goeasy.io',	//应用所在的区域地址: [hangzhou.goeasy.io, 新加坡暂不支持IM，敬请期待]
	appkey: 'BC-1b91641947004711b98951e0e87293f0', // common key,
	modules: ['im']
});

Vue.prototype.GoEasy = GoEasy;
Vue.prototype.goEasy = goEasy;

